import React from "react";
import { PageProps } from "gatsby";
import { window } from "browser-monads";

import * as styles from "./post.module.scss";
import markdownToHtml from "helpers/utils/markdownLoader";
import { GetStarted, Related } from "components/design-system";
import SEO from "components/SEO";
import { BlogFacebook, BlogLinkedIn, BlogTwitter } from "assets/media/svgs";

import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import { Image, Post } from "types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

interface BlogPostTemplateProps extends PageProps {
  pageContext: {
    post: Post;
    relatedPosts: Post[];
  };
}

export const PostTemplate: React.FC<BlogPostTemplateProps & PageProps> = ({
  pageContext: {
    post: { attributes: post },
    relatedPosts,
  },
}) => {
  const categoryPath = `/blog/${post.category.data.attributes.slug}`;
  const relatedItems = relatedPosts.map(
    ({
      attributes: {
        title,
        featuredImage: {
          data: {
            attributes: { localFile: image, ext, url },
          },
        },
        slug,
        description,
      },
    }) =>
      Object.assign(
        {
          title,
          link: `${categoryPath}/${slug}`,
          excerpt: description,
        },
        ext === ".gif" ? { featuredImage: url } : { image }
      )
  );

  return (
    <>
      <SEO
        type="article"
        slug={`${categoryPath}/${post.slug}`}
        title={post.title}
        datePublished={post.date}
        image={post.featuredImage.data.attributes.url}
        authorName={post.author?.data.attributes.name}
        description={post.description}
      />
      <PostPreview {...post} />
      <Related
        title="More posts like this"
        align="left"
        items={relatedItems}
        className={styles.Post_related}
      />
      <GetStarted />
    </>
  );
};

export const PostPreview: React.FC<Post["attributes"]> = (post) => {
  const postURL = `${window.location.href}`;

  const dateOptions = {
    month: "long",
    day: "numeric",
    weekday: "long",
    year: "numeric",
  };

  const {
    attributes: { url: imageUrl, ext: imageExt, localFile: imageLocalfile },
  } = post.featuredImage.data as Image;

  const {
    attributes: { url: avatarUrl, ext: avatarExt, localFile: avatarLocalfile },
  } = post.author?.data.attributes.avatar.data as Image;

  const featuredImage = getImage(imageLocalfile);
  const authorAvatar = getImage(avatarLocalfile);

  const formattedDate = new Date(post.date).toLocaleDateString(
    "en-US",
    dateOptions as any
  );

  return (
    <div className={styles.Post}>
      <article className={styles.Post_container}>
        <div>
          <div className={styles.Post_header}>
            <div className={styles.Post_meta}>
              <p className={styles.Post_header_category}>
                {post.category.data.attributes.name}
              </p>
              <p className={styles.Post_header_date}>{formattedDate}</p>
            </div>
            <h1 className={styles.Post_header_title}>{post.title}</h1>
            <div
              className={styles.Post_header_description}
              dangerouslySetInnerHTML={{
                __html: markdownToHtml(post.description),
              }}
            />
          </div>
          {imageExt === ".gif" ? (
            <div className={styles.Post_featuredImage}>
              <img src={imageUrl} alt={post.title} />
            </div>
          ) : (
            <GatsbyImage
              className={styles.Post_featuredImage}
              image={featuredImage!}
              alt={post.title}
            />
          )}
          <div className={styles.Post_content}>
            <div className={styles.Post_content_left}>
              <div className={styles.Post_author}>
                {avatarExt === ".gif" ? (
                  <div className={styles.Post_author_avatar}>
                    <img
                      src={avatarUrl}
                      alt={post.author?.data.attributes.name!}
                    />
                  </div>
                ) : (
                  <GatsbyImage
                    className={styles.Post_author_avatar}
                    image={authorAvatar!}
                    alt={post.author?.data.attributes.name!}
                  />
                )}

                <div className={styles.Post_author_meta}>
                  <p className={styles.Post_author_name}>
                    {post.author?.data.attributes.name}
                  </p>
                  <p className={styles.Post_author_role}>
                    {post.author?.data.attributes.title}
                  </p>
                </div>
              </div>
              <div className={styles.Post_share}>
                <p>SHARE THIS POST ON</p>
                <div className={styles.Post_share_icons}>
                  <FacebookShareButton url={postURL} quote={post.title}>
                    <div>
                      <BlogFacebook />
                    </div>
                  </FacebookShareButton>

                  <TwitterShareButton url={postURL} title={post.title}>
                    <div>
                      <BlogTwitter />
                    </div>
                  </TwitterShareButton>
                  <LinkedinShareButton url={postURL} title={post.title}>
                    <div>
                      <BlogLinkedIn />
                    </div>
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
            <div
              className={styles.Post_content_main}
              dangerouslySetInnerHTML={{
                __html: markdownToHtml(post.content),
              }}
            />
          </div>
        </div>
      </article>
    </div>
  );
};

export default PostTemplate;
