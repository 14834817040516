// extracted by mini-css-extract-plugin
export var Post = "post-module--Post--tX8En";
export var Post_author = "post-module--Post_author--2bArV";
export var Post_author_avatar = "post-module--Post_author_avatar--Pc7El";
export var Post_author_meta = "post-module--Post_author_meta--0K7UD";
export var Post_author_name = "post-module--Post_author_name--H4vbw";
export var Post_author_role = "post-module--Post_author_role--xO8c2";
export var Post_container = "post-module--Post_container--u9RHJ";
export var Post_content = "post-module--Post_content--10Yu5";
export var Post_content_left = "post-module--Post_content_left--NcpiP";
export var Post_content_main = "post-module--Post_content_main--rKPXt";
export var Post_featuredImage = "post-module--Post_featuredImage--M1wtr";
export var Post_header = "post-module--Post_header--qeULl";
export var Post_header_category = "post-module--Post_header_category--ar7Fe";
export var Post_header_date = "post-module--Post_header_date--UDzr0";
export var Post_header_description = "post-module--Post_header_description--JR9Zq";
export var Post_header_title = "post-module--Post_header_title--+MuBr";
export var Post_meta = "post-module--Post_meta--t4M9O";
export var Post_related = "post-module--Post_related--ATGdc";
export var Post_share = "post-module--Post_share--GF+uo";
export var Post_share_icons = "post-module--Post_share_icons--E+FVD";
export var container = "post-module--container--7PoO1";
export var gatsbyImageWrapperConstrained = "post-module--gatsby-image-wrapper-constrained--slGDB";
export var gridSpan1010 = "post-module--grid-span-10-10--EiM9X";
export var gridSpan1011 = "post-module--grid-span-10-11--dPAtu";
export var gridSpan102 = "post-module--grid-span-10-2--1iqH2";
export var gridSpan103 = "post-module--grid-span-10-3--1kc4u";
export var gridSpan104 = "post-module--grid-span-10-4--eTwuY";
export var gridSpan105 = "post-module--grid-span-10-5--wkiFF";
export var gridSpan106 = "post-module--grid-span-10-6--0VpZ8";
export var gridSpan107 = "post-module--grid-span-10-7--2dFUL";
export var gridSpan108 = "post-module--grid-span-10-8--uOP3F";
export var gridSpan109 = "post-module--grid-span-10-9--KIAu1";
export var gridSpan110 = "post-module--grid-span-1-10--xE+x9";
export var gridSpan111 = "post-module--grid-span-1-11--17J5i";
export var gridSpan1110 = "post-module--grid-span-11-10--6a9fd";
export var gridSpan1111 = "post-module--grid-span-11-11--w+dk1";
export var gridSpan112 = "post-module--grid-span-11-2--FVroC";
export var gridSpan113 = "post-module--grid-span-11-3--tnhHj";
export var gridSpan114 = "post-module--grid-span-11-4--jB1Xp";
export var gridSpan115 = "post-module--grid-span-11-5--7dHwz";
export var gridSpan116 = "post-module--grid-span-11-6--i13PA";
export var gridSpan117 = "post-module--grid-span-11-7--ke6BC";
export var gridSpan118 = "post-module--grid-span-11-8--cveU-";
export var gridSpan119 = "post-module--grid-span-11-9--epjxW";
export var gridSpan12 = "post-module--grid-span-1-2--4Pl6p";
export var gridSpan1210 = "post-module--grid-span-12-10--N61Dd";
export var gridSpan1211 = "post-module--grid-span-12-11--LuEeT";
export var gridSpan122 = "post-module--grid-span-12-2--HBdmO";
export var gridSpan123 = "post-module--grid-span-12-3--k5SXA";
export var gridSpan124 = "post-module--grid-span-12-4--DGzKr";
export var gridSpan125 = "post-module--grid-span-12-5--Y1NFJ";
export var gridSpan126 = "post-module--grid-span-12-6--rxXDs";
export var gridSpan127 = "post-module--grid-span-12-7--610WV";
export var gridSpan128 = "post-module--grid-span-12-8--AvBvU";
export var gridSpan129 = "post-module--grid-span-12-9--pJgSt";
export var gridSpan13 = "post-module--grid-span-1-3--20-en";
export var gridSpan14 = "post-module--grid-span-1-4--7F50a";
export var gridSpan15 = "post-module--grid-span-1-5---plM7";
export var gridSpan16 = "post-module--grid-span-1-6--bZUMg";
export var gridSpan17 = "post-module--grid-span-1-7--MQVO+";
export var gridSpan18 = "post-module--grid-span-1-8--em2Nh";
export var gridSpan19 = "post-module--grid-span-1-9--r9ilp";
export var gridSpan210 = "post-module--grid-span-2-10--VAqvi";
export var gridSpan211 = "post-module--grid-span-2-11--K6ApX";
export var gridSpan22 = "post-module--grid-span-2-2--4L94L";
export var gridSpan23 = "post-module--grid-span-2-3--e9sE8";
export var gridSpan24 = "post-module--grid-span-2-4--Gq0nY";
export var gridSpan25 = "post-module--grid-span-2-5--Vz7gK";
export var gridSpan26 = "post-module--grid-span-2-6--KKvqp";
export var gridSpan27 = "post-module--grid-span-2-7--xjV5Z";
export var gridSpan28 = "post-module--grid-span-2-8--GntQv";
export var gridSpan29 = "post-module--grid-span-2-9--Uo3Bx";
export var gridSpan310 = "post-module--grid-span-3-10--sEM-Q";
export var gridSpan311 = "post-module--grid-span-3-11--7ztRm";
export var gridSpan32 = "post-module--grid-span-3-2--xWXkv";
export var gridSpan33 = "post-module--grid-span-3-3--+pwuD";
export var gridSpan34 = "post-module--grid-span-3-4--lt9U4";
export var gridSpan35 = "post-module--grid-span-3-5--6Bhnb";
export var gridSpan36 = "post-module--grid-span-3-6--zrZEY";
export var gridSpan37 = "post-module--grid-span-3-7--naP1O";
export var gridSpan38 = "post-module--grid-span-3-8--v78HM";
export var gridSpan39 = "post-module--grid-span-3-9--OlxW7";
export var gridSpan410 = "post-module--grid-span-4-10---0KaX";
export var gridSpan411 = "post-module--grid-span-4-11--xU1Df";
export var gridSpan42 = "post-module--grid-span-4-2--NaP73";
export var gridSpan43 = "post-module--grid-span-4-3--VjY-v";
export var gridSpan44 = "post-module--grid-span-4-4--jMM0i";
export var gridSpan45 = "post-module--grid-span-4-5--WS+5p";
export var gridSpan46 = "post-module--grid-span-4-6--WyS3E";
export var gridSpan47 = "post-module--grid-span-4-7--oSULI";
export var gridSpan48 = "post-module--grid-span-4-8--a-ehs";
export var gridSpan49 = "post-module--grid-span-4-9--6-Wlv";
export var gridSpan510 = "post-module--grid-span-5-10--4SGHZ";
export var gridSpan511 = "post-module--grid-span-5-11--VHnZI";
export var gridSpan52 = "post-module--grid-span-5-2--5eC-6";
export var gridSpan53 = "post-module--grid-span-5-3--w3bfa";
export var gridSpan54 = "post-module--grid-span-5-4--9fUqU";
export var gridSpan55 = "post-module--grid-span-5-5--ChHFO";
export var gridSpan56 = "post-module--grid-span-5-6--cSKn8";
export var gridSpan57 = "post-module--grid-span-5-7--+Jfjw";
export var gridSpan58 = "post-module--grid-span-5-8--xgJzj";
export var gridSpan59 = "post-module--grid-span-5-9--rJT26";
export var gridSpan610 = "post-module--grid-span-6-10--R9mzu";
export var gridSpan611 = "post-module--grid-span-6-11--pOGkL";
export var gridSpan62 = "post-module--grid-span-6-2--tFonf";
export var gridSpan63 = "post-module--grid-span-6-3--lf+Xk";
export var gridSpan64 = "post-module--grid-span-6-4--U4QRH";
export var gridSpan65 = "post-module--grid-span-6-5--AaG0o";
export var gridSpan66 = "post-module--grid-span-6-6--3BTsZ";
export var gridSpan67 = "post-module--grid-span-6-7--xsekP";
export var gridSpan68 = "post-module--grid-span-6-8--7EYlO";
export var gridSpan69 = "post-module--grid-span-6-9--51ujm";
export var gridSpan710 = "post-module--grid-span-7-10--qI6MX";
export var gridSpan711 = "post-module--grid-span-7-11--Nu8Kd";
export var gridSpan72 = "post-module--grid-span-7-2--YXCLz";
export var gridSpan73 = "post-module--grid-span-7-3--vRK08";
export var gridSpan74 = "post-module--grid-span-7-4--u2gEU";
export var gridSpan75 = "post-module--grid-span-7-5--99kws";
export var gridSpan76 = "post-module--grid-span-7-6--S6itH";
export var gridSpan77 = "post-module--grid-span-7-7--kaFjT";
export var gridSpan78 = "post-module--grid-span-7-8--hdDoD";
export var gridSpan79 = "post-module--grid-span-7-9--2UT04";
export var gridSpan810 = "post-module--grid-span-8-10--vItfG";
export var gridSpan811 = "post-module--grid-span-8-11--YE4sg";
export var gridSpan82 = "post-module--grid-span-8-2--F2Cju";
export var gridSpan83 = "post-module--grid-span-8-3--nI4ss";
export var gridSpan84 = "post-module--grid-span-8-4--+7FcL";
export var gridSpan85 = "post-module--grid-span-8-5--KVDif";
export var gridSpan86 = "post-module--grid-span-8-6--ZDe5Y";
export var gridSpan87 = "post-module--grid-span-8-7--2fMut";
export var gridSpan88 = "post-module--grid-span-8-8--3N-Ix";
export var gridSpan89 = "post-module--grid-span-8-9--xyMDL";
export var gridSpan910 = "post-module--grid-span-9-10--tsZvg";
export var gridSpan911 = "post-module--grid-span-9-11--r2a58";
export var gridSpan92 = "post-module--grid-span-9-2--T7aGL";
export var gridSpan93 = "post-module--grid-span-9-3--uOYQb";
export var gridSpan94 = "post-module--grid-span-9-4--ACxGb";
export var gridSpan95 = "post-module--grid-span-9-5--EmCe5";
export var gridSpan96 = "post-module--grid-span-9-6--RiZaL";
export var gridSpan97 = "post-module--grid-span-9-7--ZvHlF";
export var gridSpan98 = "post-module--grid-span-9-8--FTShn";
export var gridSpan99 = "post-module--grid-span-9-9--fd+3w";
export var textBreak = "post-module--text-break--sfHSL";